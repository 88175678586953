import React from "react";
import { Link } from "gatsby";
import cardStyles from "./card.module.scss";

const Card = ({ data }) => {

  if (data.url.match("^(ftp|http|https)://(w+:{0,1}w*@)?(S+)(:[0-9]+)?(/|/([w#!:.?+=&%@!-/]))?$")) {
    return (
      <React.Fragment>
        <div class="col-md-4">
          <Link to={data.url}>
            <div className={`${cardStyles.card} grow`}>
              <div className={cardStyles.block}>
                <h3 className={cardStyles.title}>{data.title}</h3>
                <div className={cardStyles.text}>{data.summary.summary}</div>
              </div>
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div class="col-md-4">
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            <div className={`${cardStyles.card} grow`}>
              <div className={cardStyles.block}>
                <h3 className={cardStyles.title}>{data.title}</h3>
                <div className={cardStyles.text}>
                  {data.summary.summary}
                </div>
              </div>
            </div>
          </a>
        </div>
      </React.Fragment>
    );
  }
};

export default Card;
